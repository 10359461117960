<template>
  <v-card class="sticky-banner py-2 center" rounded="0">
    <div class="d-flex align-center ml-5">
      <v-spacer />
      <v-btn
        class="secondary-button-mini ml-2"
        @click="helpClick"
      >
        Hjælp
        <v-icon class="ml-2" end>mdi-help-circle-outline</v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script setup lang="ts">

const emit = defineEmits<{
  helpClick: []
  }>();
function helpClick() {
  emit('helpClick');
}

</script>

<style scoped>
h1 {
  font-size: 20px;
  font-weight: 600;
}

.sticky-banner {
  position: sticky;
  align-self: flex-start;
  top: 0;
  z-index: 1;
}
</style>
