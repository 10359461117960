import '@dims/components/dist/dims-components.css';
import { init } from '@dims/components';
import componentProvider from '@/componentProvider';
import App from '@/App.vue';

const siteConfig = {
  agreementId: '02220022',
  agreementNameLong: '02.22 It-drift',
};

init(App, document, null, siteConfig, componentProvider);
