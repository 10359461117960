import { Calculator, Offer, TenderStateEnum, Tender, toCurrencyFormatDK2, OfferEvaluation, OfferEvaluationData, DraftTender } from '@dims/components';
import { Offer0222 } from '@/models/Offer';
import { Tender0222 } from '@/models/Tender';
import { BPQCriteriaEnum } from '../services/bpqCriteria';

export const getTotalPricePerPoint = (offer: Offer0222, _tender: Tender0222): number => offer.data.bpqScore ?? 0;

export const calculatePointPrice = (offer: Offer0222, _tender: Tender0222, _totalBpqPoints: number): number => {
  const result = (offer.data.tcoCalculationResult?.tcoPrice ?? 0) / _totalBpqPoints;
  return result;
};

const checkValidScores = (offerEvaluation: OfferEvaluation): boolean => {
  if (offerEvaluation.data) {
    if (offerEvaluation.data.some((data) => data.score)) {
      return true;
    }
  }

  return false;
};

export const calculateBPQRatioScore = (offer: Offer0222, tender: Tender0222, offerEvaluation?: OfferEvaluation): number => {
  const td = tender.data;

  const qualityPercentage = (td.bpqCriteriaQualityPoints ?? 0);
  const transitionPercentage = (td.bpqCriteriaTransitionPoints ?? 0);
  const itSecurityPercentage = (td.bpqCriteriaItSecurityPoints ?? 0);
  const governancePercentage = (td.bpqCriteriaGovernancePoints ?? 0);

  if (offerEvaluation?.data && checkValidScores(offerEvaluation)) {
    const totalScore = getOfferEvaluationElementScore(offerEvaluation.data, 'Quality') * qualityPercentage
    + getOfferEvaluationElementScore(offerEvaluation.data, 'Transition') * transitionPercentage
    + getOfferEvaluationElementScore(offerEvaluation.data, 'ItSecurity') * itSecurityPercentage
    + getOfferEvaluationElementScore(offerEvaluation.data, 'Governance') * governancePercentage;

    return Number(totalScore.toFixed(1));
  }

  return 0;
};

function getOfferEvaluationElementScore(offerEvaluationData: OfferEvaluationData[], key: BPQCriteriaEnum) {
  const offerEvaluationElement = offerEvaluationData.find((o: OfferEvaluationData) => o.text === key);

  if (offerEvaluationElement) {
    return offerEvaluationElement.score ?? 0;
  }

  return 0;
}

export const convertDecimalSeperators = (value: string): string => value.toString().replace('.', ',');

export const convertDecimalSeperator = (value: number): string => convertDecimalSeperators(value.toString());

export const calculateTotalPricePerPoint = (offer: Offer0222, tender: Tender0222, offerEvaluation?: OfferEvaluation): number => {
  const bpqRatioScore = calculateBPQRatioScore(offer, tender, offerEvaluation);
  const pointPrice = calculatePointPrice(offer, tender, bpqRatioScore);

  return Number(pointPrice.toFixed(2));
};

export const tenderStub: DraftTender = {
  data: {
    requestForOfferDone: false,
    clarificationDone: false,
    specificationDone: false,
    deliveryAgreementDone: false,
  },
  agreementName: 'dummy',
  awardCriteriaType: 'BPQRatio',
  state: TenderStateEnum.Prepare,
};

/* Expose the agreement specific calculations in a generic way to be used by shared components */
export class Calculator0222 implements Calculator {
  isScoreBelowConditionalThreshold_BackendCalculation(offer: Offer0222): boolean {
    // Only if points have been entered (i.e. PointScore > 0) is the flag considered
    return ((offer.data.bpqPointScore ?? 0) > 0)
      ? (offer.data.bpqPointScoreTooLow ?? true)
      : false;
  }

  isScoreBelowConditionalThreshold_FrontendCalculation(_offer: Offer0222, _tender: Tender0222): boolean {
    // For 02.22 this will always return false, since this is only calculated backend
    return false;
  }

  sortByCustomRanking(offers: Offer0222[]): Offer[] {
    return offers.sort(
      (a, b) => (a.data.customRanking ?? 0) - (b.data.customRanking ?? 0),
    );
  }

  calculateRelativeScore(offer: Offer0222, tender: Tender0222, _conditionalOffers: Offer[], offerEvaluation?: OfferEvaluation) {
    return calculateBPQRatioScore(offer, tender, offerEvaluation);
  }

  calculatePrice(offer: Offer) {
    return (offer as Offer0222).data.tcoCalculationResult?.tcoPrice ?? 0;
  }

  sortByPrice(offers: Offer[]) {
    return offers.sort(
      // lowest price first, so a - b
      (a, b) => this.calculatePrice(a) - this.calculatePrice(b),
    );
  }

  sortBPQBestFirst(offers: Offer[], tender: Tender, offersEvaluations: OfferEvaluation[]) {
    return offers.sort(
      // should be negative if a is the best offer, since we want best first
      // since the lowest price is best, result is price(a) - price(b)
      (a, b) => calculateTotalPricePerPoint(a, tender, offersEvaluations.find((oe) => oe.offerId === a.id))
        - calculateTotalPricePerPoint(b, tender, offersEvaluations.find((oe) => oe.offerId === b.id)),
    );
  }

  contractSum(offer: Offer) {
    return offer.contractSum ?? 0;
  }

  /** Shown in 'Samlede omkostninger' column for non-BPQ evaluation  */
  totalCost(offer: Offer0222) {
    return offer.data.tcoCalculationResult?.tcoPrice ?? 0;
  }

  /** Shown in 'Samlet score' column for BPQ evaluation
   * should represent the factor used for ordering the offers */
  bpqScoreText(offer: Offer0222, tender: Tender, _conditionalOffers: Offer[], offerEvaluation?: OfferEvaluation) {
    const pricePrPoint = calculateTotalPricePerPoint(offer, tender, offerEvaluation);
    if (pricePrPoint > 0 && Number.isFinite(pricePrPoint)) return `${toCurrencyFormatDK2(pricePrPoint)} / point`;
    return 'Kvalitets-score ikke indtastet';
  }
}

export function getCalculator() {
  return new Calculator0222();
}
