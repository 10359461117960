import { AgreementSpecific } from '@dims/components';
import FilterConfiguration from '@/services/FilterConfiguration';
import scenarios from './models/scenarios';
import bpqCriteria from './services/bpqCriteria';

const agreementSpecific: AgreementSpecific = {
  createFilter() { return new FilterConfiguration(); },
  scenarios,
  bpqCriteria,
};

export default agreementSpecific;
