<template>
  <v-card flat>
    <v-card-title>
      <div class="header text-primary">Behovsafdækning</div>
    </v-card-title>
    <v-card-text>
      <p>Du skal i behovsafdækningen tage en række overordnede valg, der skal hjælpe med at målrette materialet til den efterfølgende kravspecifikation.
      </p>
      <p>Du skal igennem følgende trin:</p>
      <ol class="mb-4">
        <li>Først skal du tage stilling til, hvilke af de overordnede kategorier du vil købe ind indenfor:
          <ul>
            <li>Cloud</li>
            <li>Datacenterdrift</li>
            <li>Co-location</li>
            <li>Netværksdrift</li>
            <li>Applikationsdrift</li>
            <li>Slutbruger</li>
            <li>Sikkerhedsservices</li>
          </ul>
        </li>
        <li class="mt-4">
          Dernæst skal du tage stilling til, hvilke underordnede services der skal indgå i din anskaffelse.
        </li>
        <li class="mt-4">
          Du skal samtidigt tage stilling til en række spørgsmål, der relaterer sig til dine valg. Det efterfølgende materiale til udarbejdelsen af kravspecifikation bliver herefter tilpasset dine valg.
        </li>
      </ol>
      <p>Når du har udfyldt alle trin i behovsafdækningen, får du en samlet rapport, som kan downloades og deles i din organisation.</p>
      <p>Hvis du har spørgsmål eller brug for rådgivning til behovsafdækningen, er du altid velkommen til at kontakte os.</p>
      <IconExplanation />
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">

</script>

<style scoped>
.pre-formatted {
  white-space: pre-wrap !important;
}

.header {
  font-size: 30px;
}
</style>
