import { useStore } from '@dims/components';
import { defineStore } from 'pinia';
import { DrafTender0222 } from '@/models/Tender';

export const useStore0222 = defineStore('0222', {
  getters: {
    draftTender() {
      const store = useStore();
      return store.draftTender as DrafTender0222 | null;
    },
  },
  actions: {
    setDraftTender(tender: DrafTender0222) {
      const store = useStore();
      store.setDraftTender(tender);
    },
  },
});

export default useStore0222;
