import { TemplateQuestionPanelState, Tab, TabType } from '@dims/components';

const customerSpecificationTabs: Tab[] = [
  {
    title: 'Sortimentsbilag',
    section: 3,
    toc: 12,
    panelState: TemplateQuestionPanelState.INIT,
  },
  {
    title: 'Øvrige bilag',
    section: 4,
    toc: 1,
    panelState: TemplateQuestionPanelState.INIT,
  },
  {
    title: 'Tilbudsliste',
    section: 5,
    toc: 1,
    panelState: TemplateQuestionPanelState.INIT,
  },
];

const supplierSpecificationTabs: Tab[] = [
  {
    title: 'Kravbesvarelse af sortimentsbilag',
    section: 0,
    panelState: TemplateQuestionPanelState.INIT,
  },
  {
    title: 'Kravbesvarelse af øvrige bilag',
    section: 1,
    panelState: TemplateQuestionPanelState.INIT,
  },
];

const supplierOfferTabs = [
  {
    title: 'Tilbudsliste',
    section: 2,
    panelState: TemplateQuestionPanelState.INIT,
  },
];

const questionnaireTabConfig = {
  getTabs: (tenderId: string, type: TabType): Promise<Tab[]> => {
    let tabs: Tab[] = [];

    switch (type) {
      case 'CustomerSpecification':
        tabs = customerSpecificationTabs;
        break;
      case 'SupplierSpecification':
        tabs = supplierSpecificationTabs;
        break;
      case 'SupplierOffer':
        tabs = supplierOfferTabs;
        break;
    }

    return new Promise((resolve) => {
      resolve(tabs);
    });
  },
};

export default questionnaireTabConfig;
