<!--
  DO NOT EDIT THIS FILE

  File is duplicated in site and across sites, so should be kept in sync
-->
<template>
  <OfferSandboxBase @reloadOfferSample="createSandboxOfferQuestionnaire()" backgroundColor="white">
    <v-container fluid>
      <v-row>
        <v-col>
          <v-card class="pa-3 bg-canvas">
            <v-card-text>
              <div class="d-flex">
                <div>
                  <div class="d-flex align-center">
                    <h1 class="mt-0 mb-2 font-weight-bold">Udfyld dit tilbud</h1>
                    <v-chip color="darkPurple" class="ml-2">Leverandørvisning</v-chip>
                  </div>

                  <div class="mt-2 mb-7">
                    <p>
                      Kravbesvarelse og prisafgivelse herunder udgør dit tilbud
                    </p>
                    <p>
                      Udfyld dit tilbud ved (1) at besvare evalueringskravene,
                      (2) afgive priser og (3) bekræfte, at dit tilbud opfylder
                      samtlige af kundens krav.
                    </p>
                  </div>
                </div>
              </div>
              <v-card>
                <v-card-text class="pa-10">
                  <v-row>
                    <v-col cols="auto">
                      <StepStatus
                        :label="1"
                        :complete="isSectionComplete([0, 1])"
                      />
                    </v-col>
                    <v-col>
                      <h2 class="mt-0 mb-2">Kravbesvarelse</h2>
                      <p>
                        Besvarelsen er opdelt i 'Sortimentsbilag', som omhandler
                        krav til selve ydelsen samt 'Øvrige bilag', der vedrører
                        øvrige krav til samarbejdet.
                      </p>
                      <p>
                        Kravene er enten <b>kontraktkrav</b> eller
                        <b>evalueringskrav</b>.
                      </p>
                      <p>
                        <b>Kontraktkrav</b> er krav, dit tilbud skal leve op
                        til. De kræver ikke besvarelse fra din side, men er
                        afgørende for, om dit tilbud opfylder alle kundens krav
                        (nedenstående trin 3).
                      </p>
                      <p>
                        <b>Evalueringskrav</b> indgår i kundens evaluering af
                        dit tilbud, og skal besvares. Det gør du ved at
                        downloade og besvare det enkelte 'Evalueringsdokument',
                        og uploade din besvarelse.
                      </p>
                      <v-dialog v-model="offerPreviewDialog" max-width="95%">
                        <template #activator="{ props }">
                          <ArrowButton
                            v-bind="props"
                            class="mt-2"
                            :class="{ secondary: isSectionComplete([0, 1]) }"
                          >Besvar evalueringskrav</ArrowButton>
                        </template>
                        <v-card>
                          <OfferDetailsForm
                            v-if="offerQuestionnaire"
                            :tender
                            :offerQuestionnaire
                            :tabType="'SupplierSpecification'"
                            :readonly="true"
                            @closeDialog="closeOfferPreviewDialog"
                          />
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-divider class="my-8"></v-divider>
                  <v-row>
                    <v-col cols="auto">
                      <StepStatus
                        :label="2"
                        :complete="isSectionComplete([2])"
                      />
                    </v-col>
                    <v-col>
                      <h2 class="mt-0 mb-2">Prisafgivelse</h2>
                      <p>
                        Prisafgivelsen er opdelt i en række priselementer, der
                        afspejler kundens indkøb fx 'Driftsomkostninger'.
                      </p>
                      <p>
                        Hvert priselement har én eller flere pristabeller med
                        prispunkter, du skal udfylde. For hver pristabel findes
                        en vejledning til udfyldelse. Vejledningen beskriver
                        også hvordan den evalueringstekniske pris beregnes. I
                        takt med din udfyldelse, kan du se prisen aggregeret op
                        pr. pristabel og priselement.
                      </p>
                      <p>
                        I 'Opsummering' (til højre), kan du følge de priser, du
                        har afgivet, samt den evalueringstekniske pris
                      </p>
                      <p>
                        <b>Bemærk</b> priser skal afgives ekskl. moms og inkl.
                        kundebetaling til SKI.
                        <DimsTooltip>
                          <p>
                            Leverandøren skal på vegne af Kunden videreformidle
                            kundebetalingen på 2,0 % af Kundens købssum til SKI.
                            Leverandøren skal derfor indregne kundens betaling
                            til SKI på 2,0 % i sine tilbudspriser i det konkrete
                            indkøb.
                          </p>
                          <p>Kundebetalingen beregnes efter følgende formel:</p>
                          <p class="text-decoration-underline">
                            Kundens købssum eksklusive moms i DKK x 2,0 % =
                            Kundebetalingen i DKK
                          </p>
                          <p>
                            Leverandørens rapportering samt formidling af
                            kundens betaling til SKI er beskrevet nærmere i
                            Bilag 1 Trepartsaftale.
                          </p>
                        </DimsTooltip>
                      </p>
                      <v-dialog v-model="offerFormDialog" max-width="95%">
                        <template #activator="{ props }">
                          <ArrowButton
                            v-bind="props"
                            class="mt-2"
                            :class="{ secondary: isSectionComplete([2]) }"
                          >Afgiv priser
                          </ArrowButton>
                        </template>
                        <v-card>
                          <OfferDetailsForm
                            v-if="offerQuestionnaire"
                            :tender
                            :offerQuestionnaire
                            :tabType="'SupplierOffer'"
                            :readonly="true"
                            @closeDialog="closeOfferFormDialog"
                          />
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-divider class="my-8"></v-divider>
                  <v-row>
                    <v-col cols="auto">
                      <StepStatus
                        :label="3"
                        :complete="false"
                      />
                    </v-col>
                    <v-col>
                      <h2 class="mt-0 mb-2">Kundens kravspecifikation</h2>
                      <div>
                        Bekræft om dit tilbud opfylder samtlige krav i kundens
                        kravspecifikation.
                      </div>
                      <v-radio-group
                        hide-details
                        disabled
                        density="compact"
                      >
                        <v-radio :value="true">
                          <template #label>
                            <small>Ja, alle krav er opfyldt</small>
                          </template>
                        </v-radio>
                        <v-radio :value="false">
                          <template #label>
                            <small>
                              Nej, alle krav er
                              <span class="font-weight-bold">ikke</span>
                              opfyldt
                            </small>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-divider class="my-8"></v-divider>
                  <v-row>
                    <v-col cols="auto">
                      <StepStatus :label="4" :complete="false" />
                    </v-col>
                    <v-col>
                      <p>
                        Du kan her uploade det ESPD, som ligger til grund for
                        din optagelse på aftalen.
                      </p>
                      <v-btn class="primary-button-with-arrow">
                        <span>Upload ESPD-dokument</span>
                        <v-icon size="small">mdi-upload</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-divider class="my-8"></v-divider>
                  <v-row>
                    <v-col cols="auto">
                      <StepStatus
                        :label="5"
                        :complete="false"
                      />
                    </v-col>
                    <v-col>
                      <p>Du kan her udfylde et tilbudsbrev og vedhæfte dette til dit tilbud, såfremt det er relevant. Dette er valgfrit.</p>
                      <v-btn
                        class="primary-button-with-arrow"
                      >
                        <span>Upload tilbudsbrev</span>
                        <v-icon size="small">mdi-upload</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-divider class="my-8"></v-divider>
                  <v-row>
                    <v-col cols="auto">
                      <StepStatus :label="6" :complete="false" />
                    </v-col>
                    <v-col>
                      <h2 class="mt-0 mb-2">Se og send tilbud</h2>
                      <p>Færdiggør trin 1, 2 og 3, for at sende dit tilbud</p>
                      <v-dialog
                        persistent
                        scrollable
                        max-width="80%"
                      >
                        <template #activator="{ props }">
                          <ArrowButton
                            disabled
                            v-bind="props"
                            class="mt-2"
                          >Se og send tilbud</ArrowButton>
                        </template>
                        <v-card>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <TcoCalculationSummary
            :questions="tcoCalculationQuestions"
            :tcoCalculationResult
            cardStyle="#fff9ef"
            tableStyle="transparent"
          />
        </v-col>
      </v-row>
    </v-container>
  </OfferSandboxBase>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { Tender, OfferSandboxBase, OfferDetailsForm, useBackend, usePreviewOfferQuestionnaire, QuestionnaireRoot } from '@dims/components';
import TcoCalculationSummary from './OfferDetails/TcoCalculation/TcoCalculationSummary.vue';

const { tender } = defineProps<{ tender: Tender }>();
const backend = useBackend();
const offerFormDialog = ref(false);
const offerPreviewDialog = ref(false);
const offerQuestionnaire = ref<QuestionnaireRoot>();

async function createSandboxOfferQuestionnaire() {
  offerQuestionnaire.value = await usePreviewOfferQuestionnaire(tender.id, backend);
}

// Questions used for offer status overview. Exclude "Generelle informationer"
const tcoCalculationQuestions = computed(() => offerQuestionnaire.value?.questionnaire.questions[2]?.questions?.filter((q) => q.questionId !== '443e0e09-8527-4a98-a4a2-4e0771427ad8') ?? []);

const tcoCalculationResult = computed(() => offerQuestionnaire.value?.questionnaire.tcoCalculationResult);

function isSectionComplete(sections: number[]) {
  const topLevelQuestions = offerQuestionnaire.value?.questionnaire.questions;
  return topLevelQuestions
    ? sections.every((section) => topLevelQuestions[section]?.questions?.every((q) => q.complete))
    : false;
}

function closeOfferFormDialog() {
  offerFormDialog.value = false;
}

function closeOfferPreviewDialog() {
  offerPreviewDialog.value = false;
}

</script>
