import { AwardCriteriaType } from '@dims/components';

const awardCriteriaType = {
  getLabel: (type: AwardCriteriaType): string => {
    switch (type) {
    // Best price-quality ratio
      case 'BPQRatio':
        return 'Bedste forhold mellem pris og kvalitet';
      case 'TCO':
        return 'Omkostninger';
      default:
        return 'Unknown award type';
    }
  },
};

export default awardCriteriaType;
