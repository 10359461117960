import { Scenario } from '@dims/components';

const scenarios: Scenario[] = [
  { type: 'CLOUD_OPERATION', label: 'Clouddrift', description: 'Clouddrift' },
  { type: 'DATACENTER_OPERATION', label: 'Datacenterdrift', description: 'Datacenterdrift' },
  { type: 'CO_LOCATION', label: 'Co-location', description: 'Co-location' },
  { type: 'NETWORK_OPERATION', label: 'Netværksdrift', description: 'Netværksdrift' },
  { type: 'APPLICATION_OPERATION', label: 'Applikationsdrift', description: 'Applikationsdrift' },
  { type: 'ENDUSER_SERVICES', label: 'Slutbrugerservices', description: 'Slutbrugerservices' },
  { type: 'SECURITY_SERVICES', label: 'Sikkerhedsservices', description: 'Sikkerhedsservices' },
];

export default scenarios;
