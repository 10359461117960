import { PricePointDropdownValues } from '@dims/components';

function pricePointDropdownValuesFromType(type: string) {
  if (type === 'paymentType') {
    return [
      // 02.22 Driftsvederlag - readonly, dvs. kunden kan IKKE redigere valg i dropdown for default prispunkter. Men kan vælge i denne liste, når han selv laver nye prispunkter
      {
        id: 'a0558b39-ee43-482d-84a4-204b17f3e1bc',
        items: ['Rabatsats', 'Fast pris per måned', 'PxV'],
      },

      // 02.22 Vederlag for standardbestillingsydelser - kunden kan GODT redigere valg i dropdown for default prispunkter. Og kan også vælge i denne liste, når han selv laver nye prispunkter
      {
        id: '9664cad9-deb9-4af0-aec9-0ab4806b025e',
        items: ['Separat betalbar', 'Inkluderet i vederlag'],
      },

      // 02.22 Transition ind og transformation - readonly, dvs. kunden kan IKKE redigere valg i dropdown for default prispunkter. Men kan vælge i denne liste (selvom den kun har én værdi), når han selv laver nye prispunkter
      {
        id: '75d51595-e7b9-4dc2-8f7e-ed334f30ce03',
        items: ['Fast pris per betalingsbegivenhed'],
      },

      // 02.22 Optioner - kunden kan GODT redigere valg i dropdown for default prispunkter. Og kan også vælge i denne liste, når han selv laver nye prispunkter
      {
        id: '48ec2742-aa3c-4851-affc-0f97030416ab',
        items: [
          'Fast pris',
          'Fast pris per betalingsbegivenhed',
          'Fast pris per måned',
          'Pris x volumen-afregning',
          'Medgået tid',
        ],
      },
      // 02.22 Konsulentydelser - readonly, dvs. kunden kan IKKE redigere valg i dropdown for default prispunkter. Han kan i øvrigt IKKE selv lave nye prispunkter, så det er måske forkert at lave denne til en dropdown, men da det er 'paymentType', bør det være en dropdown efter min mening :-)
      {
        id: '5654fdae-4ee5-4940-8950-5b392a7dcb01',
        items: ['PxV'],
      },
      // 02.22 Vederlag ved opsigelse  - readonly, dvs. kunden kan IKKE redigere valg i dropdown for default prispunkter. Han kan i øvrigt IKKE selv lave nye prispunkter, så det er måske forkert at lave denne til en dropdown, men da det er 'paymentType', bør det være en dropdown efter min mening :-)
      {
        id: 'ab0a4680-a07e-4985-820d-8969449e5b12',
        items: ['Engangsafregning'],
      },
      // 02.22 Transition Ud  - readonly, dvs. kunden kan IKKE redigere valg i dropdown for default prispunkter. Han kan i øvrigt IKKE selv lave nye prispunkter, så det er måske forkert at lave denne til en dropdown, men da det er 'paymentType', bør det være en dropdown efter min mening :-)
      {
        id: '3bacd7e9-cf7a-4948-a756-6c9e69df6040',
        items: ['Fixed'],
      },
    ];
  }
  if (type === 'indexRegulated') {
    return [
      // 02.22 Konsulentydelser - kunden kan GODT ændre valg i dropdown for default prispunkter. Han kan IKKE selv lave nye prispunkter i denne type prispunkter
      {
        id: '5654fdae-4ee5-4940-8950-5b392a7dcb01',
        items: ['Ja', 'Nej'],
      },
    ];
  }
  if (type === 'serviceDescription') {
    return [
      // 02.22 Standardbestillingsydelser - feltet er en dropdown med kun én mulig værdi, hvilket i praksis gør det readonly for kunden, også i egne prispunkter
      {
        id: '9664cad9-deb9-4af0-aec9-0ab4806b025e',
        items: ['Bilag 12.h Standardbestillingsydelser'],
      },
    ];
  }

  return [];
}

const pricePointDropdownValues: PricePointDropdownValues = {
  getPricePointDropdownValues(type: string, configurationId: string, _groupId: string): string[] {
    const values = pricePointDropdownValuesFromType(type).find((c) => c.id === configurationId)?.items ?? [];

    if (values.length > 0) {
      return values.map((x) => x.toString());
    }
    return [];
  },
};

export default pricePointDropdownValues;
