<template>
  <div>
    <EditDescription :store :createAsTemplate />
  </div>
</template>

<script setup lang="ts">
import { useStore, EditDescription } from '@dims/components';

const { createAsTemplate = false } = defineProps<{
  /** when set to true it will create a template instead of a draft */
  createAsTemplate?: boolean }>();
const store = useStore();

</script>
