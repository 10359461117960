import { Question, DocumentDrafterQuestionnaire, QuestionnaireWrapper, RequestForOfferQuestionnaireValidator, Tender, OfferQuestionnaireValidatorFunctions, parseDecimalDk, sum, roundTo1DecimalDigit, numbersAlmostEqual } from '@dims/components';
import { DateTime } from 'luxon';
import deliveryAgreementIds from '@/models/DeliveryAgreementIds';

export default class RequestQuestionnaireValidator implements RequestForOfferQuestionnaireValidator {
  questionnaire;
  private invalidWeightingValidationMessage = '';
  private readonly offerQuestionnaireValidatorFunctions;

  constructor(readonly tender: Tender, questionnaire: DocumentDrafterQuestionnaire) {
    this.offerQuestionnaireValidatorFunctions = new OfferQuestionnaireValidatorFunctions();
    this.questionnaire = new QuestionnaireWrapper(
      this.tender.agreementConfiguration.questionnaires.requestForOffer,
      questionnaire,
    );
  }
  get incomplete() {
    return !this.questionnaire.content.complete;
  }
  minValue(question: Question): number | undefined {
    if (question.dataFieldType === 'Number') {
      return 0;
    }
    return undefined;
  }

  maxValue(_question: Question): number | undefined {
    return undefined;
  }

  minDate(question: Question): string | undefined {
    if (question.questionId === deliveryAgreementIds.idDeliveryDate) {
      return this.tender.deadlineForTender ?? undefined;
    }
    if (question.dataFieldType === 'Date') {
      return DateTime.local().toISODate();
    }
    return undefined;
  }

  get isSubcriteriaValid() {
    return numbersAlmostEqual(this.sumOfSubCriteria, 10);
  }

  weightQuestionText(questionId: string) {
    switch (questionId) {
      case deliveryAgreementIds.idQualityWeight:
        return 'kvalitet';
      case deliveryAgreementIds.idTransitionWeight:
        return 'transition';
      case deliveryAgreementIds.idSecurityWeight:
        return 'it-sikkerhed';
      case deliveryAgreementIds.idGovernanceWeight:
        return 'governance';
      default:
        return '';
    }
  }

  get weightQuestions() {
    const weightQuestionIds = [
      deliveryAgreementIds.idQualityWeight,
      deliveryAgreementIds.idTransitionWeight,
      deliveryAgreementIds.idSecurityWeight,
      deliveryAgreementIds.idGovernanceWeight,
    ];

    return this.questionnaire.content.questions.filter((q) => weightQuestionIds.includes(q.questionId));
  }

  get sumOfSubCriteria(): number {
    const values = this.weightQuestions.map((q) => {
      if (q.answers?.[0] && q.answers[0].value !== '') {
        return parseDecimalDk(q.answers[0].value) ?? 0;
      }
      return 0;
    });
    return sum(values);
  }

  get subCriteriaValidation() {
    if (this.isSubcriteriaValid) {
      return null;
    }
    const subCriteriaSum = roundTo1DecimalDigit(this.sumOfSubCriteria);
    return { message: `Summen af delkriterierne skal være 10 point (Summen er ${subCriteriaSum})` };
  }

  get isQualityWeightingValid() {
    let validationMessage = '';

    const sortedWeightQuestions = this.weightQuestions;

    if (sortedWeightQuestions.length >= 2) {
      const checkWeightingOrder = (indexA: number, indexB: number) => {
        const aAnswer = this.offerQuestionnaireValidatorFunctions.getQuestionAnswer(sortedWeightQuestions[indexA]);
        const bAnswer = this.offerQuestionnaireValidatorFunctions.getQuestionAnswer(sortedWeightQuestions[indexB]);

        const aText = this.weightQuestionText(sortedWeightQuestions[indexA]?.questionId ?? '');
        const bText = this.weightQuestionText(sortedWeightQuestions[indexB]?.questionId ?? '');

        if (aAnswer < bAnswer) {
          validationMessage = `delkriterie '${bText}' er vægtet højere end delkriterie '${aText}'`;
          return false;
        }

        if (numbersAlmostEqual(aAnswer, bAnswer)) {
          validationMessage = `delkriterie '${bText}' er vægtet lig delkriterie '${aText}'`;
          return false;
        }

        return true;
      };

      if (!checkWeightingOrder(0, 1)) {
        this.invalidWeightingValidationMessage = validationMessage;
        return false;
      }

      if (sortedWeightQuestions.length >= 3) {
        if (!checkWeightingOrder(0, 2)) {
          this.invalidWeightingValidationMessage = validationMessage;
          return false;
        }

        if (!checkWeightingOrder(1, 2)) {
          this.invalidWeightingValidationMessage = validationMessage;
          return false;
        }
      }

      if (sortedWeightQuestions.length === 4) {
        if (!checkWeightingOrder(0, 3)) {
          this.invalidWeightingValidationMessage = validationMessage;
          return false;
        }

        if (!checkWeightingOrder(1, 3)) {
          this.invalidWeightingValidationMessage = validationMessage;
          return false;
        }

        if (!checkWeightingOrder(2, 3)) {
          this.invalidWeightingValidationMessage = validationMessage;
          return false;
        }
      }
    }

    // See #24128. Only assign to "this.invalidWeightingValidationMessage" once, since
    // this is a watched value. And the Vue framework gets confused if the value
    // changes many times in the same function, and Vue will stop watching it.
    this.invalidWeightingValidationMessage = validationMessage;
    return true;
  }

  get qualityWeightingValidation() {
    if (this.isQualityWeightingValid) {
      return null;
    }
    return { message: `Delkriterier skal vægtes i faldende rækkefølge (${this.invalidWeightingValidationMessage}).` };
  }
}
