import { OfferEvaluation, OfferEvaluationData } from '@dims/components';
import { BpqScores, OfferData0222 } from '@/models/Offer';
import { TenderData0222 } from '@/models/Tender';

export type BPQCriteriaEnum =
 | 'Quality'
 | 'Transition'
 | 'ItSecurity'
 | 'Governance';

const categories: [BPQCriteriaEnum, string][] = [
  ['Quality', 'Kvaliteten af den/de tilbudte løbende ydelser'],
  ['Transition', 'Transition'],
  ['ItSecurity', 'It-sikkerhed'],
  ['Governance', 'Governance'],
];

export default {
  getCategories(tenderData: TenderData0222, offerEvaluation: OfferEvaluation) {
    return categories
      .filter((c) => {
        const [key, _label] = c;
        return this.isScoreIncluded(tenderData, key);
      })
      .map((c) => {
        const [key, label] = c;

        // Verbose version to avoid linting errors
        let score = 0;
        let reason = '';

        if (offerEvaluation.data) {
          const offerEvaluationElement = this.getOfferEvaluationElement(offerEvaluation.data, key);

          if (offerEvaluationElement) {
            score = offerEvaluationElement.score ?? 0;
            reason = offerEvaluationElement.reason ?? '';
          }
        }

        return {
          key,
          label,
          score,
          weight: this.getWeight(tenderData, key) ?? 0,
          reason,
        };
      });
  },

  getEmptyCategories(tenderData: TenderData0222) {
    return categories
      .filter((c) => {
        const [key, _label] = c;
        return this.isScoreIncluded(tenderData, key);
      })
      .map((c) => {
        const [key, label] = c;
        return {
          key,
          label,
          score: 0,
          weight: 0,
          reason: '',
        };
      });
  },
  criteriaStringToEnum(criteriaString: string): BPQCriteriaEnum | undefined {
    for (const [categoryEnum] of categories) {
      if (categoryEnum === criteriaString) {
        return categoryEnum;
      }
    }

    return undefined;
  },
  getOfferEvaluationElement(offerEvaluationData: OfferEvaluationData[], key: BPQCriteriaEnum) {
    return offerEvaluationData.find((o: OfferEvaluationData) => o.text === key);
  },
  getWeight(tenderData: TenderData0222, key: BPQCriteriaEnum) {
    const propertyName: keyof TenderData0222 = `bpqCriteria${key}Points`;
    return tenderData[propertyName];
  },
  isScoreIncluded(tenderData: TenderData0222, key: BPQCriteriaEnum) {
    return tenderData.bpqCriteria?.includes(key);
  },
  getScorePropertyName(key: BPQCriteriaEnum): keyof BpqScores {
    return `bpq${key}Score`;
  },
  getOfferScore(offerData: OfferData0222, key: BPQCriteriaEnum) {
    const propertyName = this.getScorePropertyName(key);
    return offerData[propertyName];
  },
  setOfferScore(offerData: OfferData0222, key: BPQCriteriaEnum, value: number) {
    const scorePropertyName = this.getScorePropertyName(key);
    // eslint-disable-next-line no-param-reassign
    offerData[scorePropertyName] = value;
  },
};
