import { Offer, Supplier, TenderSummary } from '@dims/components';
import { TenderData0222 } from '@/models';

export default class FilterConfiguration {
  properties = [];

  filterTender(_tender: TenderSummary) {
    return true;
  }
  filterOffer(_offer: Offer) {
    return true;
  }
  filterLot(_lot: { lotNo:string, title: string }) {
    return true;
  }
  filterSupplier(_tenderData: TenderData0222, _supplier: Supplier) {
    return true;
  }
}
