<template>
  <div>
    <h2 class="mt-0">Beregningsmetode</h2>
    <br />
    <p>
      Der foretages udregning af 3 scenarier,
      <CalculationTag
        :isHovered="hoveredTag === 'A'"
        isInteractive
        @highlightTag="highlightTag('A')"
        @unhighlightTag="unhighlightTag"
      >A</CalculationTag>
      som vægtes
      <CalculationTag
        :isHovered="hoveredTag === 'G'"
        isInteractive
        @highlightTag="highlightTag('G')"
        @unhighlightTag="unhighlightTag"
      >G</CalculationTag>
      og summeres til en samlet evalueringsteknisk pris
      <CalculationTag
        :isHovered="hoveredTag === 'B'"
        isInteractive
        @highlightTag="highlightTag('B')"
        @unhighlightTag="unhighlightTag"
      >B</CalculationTag>
    </p>
    <br />
    <p>
      I hvert scenarie regnes med en procentdel af kundens forventede volumen
      <CalculationTag
        :isHovered="hoveredTag === 'C'"
        isInteractive
        @highlightTag="highlightTag('C')"
        @unhighlightTag="unhighlightTag"
      >C</CalculationTag>
      i forhold til driftsvederlag
      <CalculationTag
        :isHovered="hoveredTag === 'D'"
        isInteractive
        @highlightTag="highlightTag('D')"
        @unhighlightTag="unhighlightTag"
      >D</CalculationTag>
      og standardbestillingsydelser.
      <CalculationTag
        :isHovered="hoveredTag === 'E'"
        isInteractive
        @highlightTag="highlightTag('E')"
        @unhighlightTag="unhighlightTag"
      >E</CalculationTag> De øvrige omkostninger
      <CalculationTag
        :isHovered="hoveredTag === 'F'"
        isInteractive
        @highlightTag="highlightTag('F')"
        @unhighlightTag="unhighlightTag"
      >F</CalculationTag>
      ændrer sig ikke imellem de tre scenarier.
    </p>
    <br />
    <p>
      <b>Scenarie 1:</b> Dette scenarie vægtes med 5%.
      <CalculationTag
        :isHovered="hoveredTag === 'G'"
        isInteractive
        @highlightTag="highlightTag('G')"
        @unhighlightTag="unhighlightTag"
      >G</CalculationTag> Her beregnes en ”beregnet omkostning”
      <CalculationTag
        :isHovered="hoveredTag === 'H'"
        isInteractive
        @highlightTag="highlightTag('H')"
        @unhighlightTag="unhighlightTag"
      >H</CalculationTag>
      ved at gange med 80%
      <CalculationTag
        :isHovered="hoveredTag === 'I'"
        isInteractive
        @highlightTag="highlightTag('I')"
        @unhighlightTag="unhighlightTag"
      >I</CalculationTag>
      af kundens forventede volumen for driftsvederlag og standard
      bestillingsydelser.
      <CalculationTag
        :isHovered="hoveredTag === 'C'"
        isInteractive
        @highlightTag="highlightTag('C')"
        @unhighlightTag="unhighlightTag"
      >C</CalculationTag>
    </p>
    <br />
    <p>
      <b>Scenarie 2:</b> Dette scenarie vægtes med 80%.
      <CalculationTag
        :isHovered="hoveredTag === 'G'"
        isInteractive
        @highlightTag="highlightTag('G')"
        @unhighlightTag="unhighlightTag"
      >G</CalculationTag> Her regnes med 100%
      <CalculationTag
        :isHovered="hoveredTag === 'I'"
        isInteractive
        @highlightTag="highlightTag('I')"
        @unhighlightTag="unhighlightTag"
      >I</CalculationTag>
      af kundens forventede volumen for driftsvederlag og standard
      bestillingsydelser
      <CalculationTag
        :isHovered="hoveredTag === 'C'"
        isInteractive
        @highlightTag="highlightTag('C')"
        @unhighlightTag="unhighlightTag"
      >C</CalculationTag>.
    </p>
    <br />
    <p>
      <b>Scenarie 3:</b> Dette scenarie vægtes med 15%.
      <CalculationTag
        :isHovered="hoveredTag === 'G'"
        isInteractive
        @highlightTag="highlightTag('G')"
        @unhighlightTag="unhighlightTag"
      >G</CalculationTag> Her beregnes en ”beregnet omkostning”
      <CalculationTag
        :isHovered="hoveredTag === 'H'"
        isInteractive
        @highlightTag="highlightTag('H')"
        @unhighlightTag="unhighlightTag"
      >H</CalculationTag>
      ved at gange 125%
      <CalculationTag
        :isHovered="hoveredTag === 'I'"
        isInteractive
        @highlightTag="highlightTag('I')"
        @unhighlightTag="unhighlightTag"
      >I</CalculationTag>
      af kundens forventede volumen for driftsvederlag og standard
      bestillingsydelser,
      <CalculationTag
        :isHovered="hoveredTag === 'C'"
        isInteractive
        @highlightTag="highlightTag('C')"
        @unhighlightTag="unhighlightTag"
      >C</CalculationTag> hvilket bevirker at alle tre spænd
      <CalculationTag
        :isHovered="hoveredTag === 'J'"
        isInteractive
        @highlightTag="highlightTag('J')"
        @unhighlightTag="unhighlightTag"
      >J</CalculationTag>
      aktiveres på følgende måde:
    </p>
    <br />
    <ul>
      <li>
        For volumen i spændet 0% - 110%
        <CalculationTag
          :isHovered="hoveredTag === 'J'"
          isInteractive
          @highlightTag="highlightTag('J')"
          @unhighlightTag="unhighlightTag"
        >J</CalculationTag>
        af kundens forventede volumen,
        <CalculationTag
          :isHovered="hoveredTag === 'C'"
          isInteractive
          @highlightTag="highlightTag('C')"
          @unhighlightTag="unhighlightTag"
        >C</CalculationTag> indregnes ingen mængderabat
      </li>
      <li>
        For volumen i spændet 110% - 120%
        <CalculationTag
          :isHovered="hoveredTag === 'J'"
          isInteractive
          @highlightTag="highlightTag('J')"
          @unhighlightTag="unhighlightTag"
        >J</CalculationTag>
        af kundens forventede volumen,
        <CalculationTag
          :isHovered="hoveredTag === 'C'"
          isInteractive
          @highlightTag="highlightTag('C')"
          @unhighlightTag="unhighlightTag"
        >C</CalculationTag> indregnes den tilbudte mængderabat for rabattrin 1
        <CalculationTag
          :isHovered="hoveredTag === 'K'"
          isInteractive
          @highlightTag="highlightTag('K')"
          @unhighlightTag="unhighlightTag"
        >K</CalculationTag>
      </li>
      <li>
        For volumen i spændet &gt;120%
        <CalculationTag
          :isHovered="hoveredTag === 'J'"
          isInteractive
          @highlightTag="highlightTag('J')"
          @unhighlightTag="unhighlightTag"
        >J</CalculationTag>
        af kundens forventede volumen,
        <CalculationTag
          :isHovered="hoveredTag === 'C'"
          isInteractive
          @highlightTag="highlightTag('C')"
          @unhighlightTag="unhighlightTag"
        >C</CalculationTag> indregnes den angivne mængderabat for rabattrin 2
        <CalculationTag
          :isHovered="hoveredTag === 'K'"
          isInteractive
          @highlightTag="highlightTag('K')"
          @unhighlightTag="unhighlightTag"
        >K</CalculationTag>
      </li>
    </ul>
    <br />
  </div>
</template>

<script setup lang="ts">
import { CalculationTag } from '@dims/components';
import { ref } from 'vue';

const emit = defineEmits<{ updateHoveredTag: [string] }>();
const hoveredTag = ref<string>();

function highlightTag(tag: string) {
  hoveredTag.value = tag;
  emit('updateHoveredTag', tag);
}
function unhighlightTag() {
  hoveredTag.value = '';
  emit('updateHoveredTag', '');
}

</script>
